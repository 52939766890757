const ANALYSIS_OPINION_TYPES = {
  AUTO: 0,
  TECHNICIAN: 1,
  DOCTOR: 2,
}

let itemsName = null;
let itemsRef = null;

export default {
  props: {
    value: Boolean,
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
  },
  data() {
    return {
      itemsName: null,
      type: 'unknown',
      typeName: '医療機関',
      dialogModel: false,
      dialogTitle: '',
      currentItem: null,
      currentMode: 'none',
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      addressRules: [
        v => !!v || '入力は必須です。',
      ],
      phoneRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      accessType: 'normal', // アクセス権限モード normal or admin
      remarks: '',
      isActionBtnDisabled: false,  // ボタン無効/有効フラグ,
      isDeletedItem: false,
      analysisOpinionTypeLabels: ['自動解析', '技師解析', '医師所見'],
    }
  },
  computed: {
    ANALYSIS_OPINION_TYPES() {
      return ANALYSIS_OPINION_TYPES
    }
  },
  methods: {
    closeDialog() {
      // console.log('closeDialog');
      this.dialogModel = false;
      this.$emit('input', false);
      this.$emit('closed', this.currentItem);
    },
    newItemForm() {
      this.mode = 'new';
      this.dialogTitle = '作成';
      this.currentItem = {
        name: '',
        type: this.type,
        postal_code: '',
        address: '',
        phone_number: '',
        center_id: null,
        reception_id: this.$store.state.receptions != null && this.$store.state.receptions.length > 0 && this.type != 'reception'
          ? this.$store.state.receptions[0].id: null,
        email: '',
        remarks: '',
        analysis_opinion_type: this.ANALYSIS_OPINION_TYPES.AUTO,
        ec_medical_id: null,
        is_contracted_analysis_option: false,
      };
      this.dialogModel = true;
      setTimeout(() => this.$refs.name.focus(), 300);

    },
    viewItemForm() {
      this.currentMode = 'view';
      this.dialogTitle = '詳細';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      this.dialogModel = true;
      this.writeLog(['view'], `詳細画面を開きました。`, this.item); // 操作記録
    },
    editItemForm() {
      this.currentMode = 'edit';
      this.dialogTitle = '更新';
      this.currentItem = JSON.parse(JSON.stringify(this.item));
      this.dialogModel = true;
      setTimeout(() => this.$refs.name.focus(), 300);
      this.writeLog(['edit'], `更新画面を開きました。`, this.item); // 操作記録
    },
    async newItem(item) {
      console.log('newItem', item);
      this.currentMode = 'new';
      item.email = item.email.trim();
      item.deleted = null;
      //item.remarks = this.remarks;
      item.holter_stock_quantity = 0;
      item.dongle_stock_quantity = 0;

      // 入力エラーがある場合は終了
      if (!this.$refs[`form`].validate()) return;

      // メールアドレスを小文字に変換する
      item.email = item.email.toLowerCase();

      if (this.type == 'customer') {
        if (item.reception_id == null) {
          await this.$root.$alert('エラー', 'デバイス受付センターが選択されていません。');
          return;
        }
      } else {
        item.reception_id = null;
      }
      item.center_id = null;

      // 検索
      let doc = itemsRef
        .where('email', '==', item.email)
        .where('deleted', '==', null);

      let snapshot = await doc.get();

      if (!snapshot.empty) {
        await this.$root.$alert('エラー', `入力された${this.typeName}のEmailアドレスは、既に登録されています。`);
        this.email = '';
        return;
      }

      // 医療機関の場合のみ解析/所見の確認
      if (this.type === 'customer') {
        item = await this.editAnalysisOpinionType(item);
        if (!item) return;
      }

      // itemの情報をFirebase上に追加登録する
      item.created = this.$firebase.firestore.FieldValue.serverTimestamp();
      item.modified = item.created;
      try {
        // 主キーは、自動割り当てを利用
        await this.$root.$progressive(itemsRef.add(item));
        this.dialogModel = false;
        this.writeLog(['new'], `新規登録しました。`, item); // 操作記録
        this.$emit('input', false);
        this.$emit('closed', item);
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', `新規${this.typeName}情報の登録に失敗しました。`);
      }
    },
    async saveItem(item) {
      console.log('saveItem', item);

      // 入力エラーがある場合は終了
      if (!this.$refs[`form`].validate()) return;

      // 医療機関の場合のみ解析/所見の確認
      if (this.type === 'customer') {
        item = await this.editAnalysisOpinionType(item);
        if (!item) return;
      }

      let updateData = item;

      // 生成・削除日時・オプション解析利用規約同意履歴は更新しない
      delete updateData.created;
      delete updateData.deleted;
      delete updateData.terms_of_services_for_analysis;

      Object.assign(updateData, {
          modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
          ec_medical_id: item.ec_medical_id && item.ec_medical_id.trim() != '' ? item.ec_medical_id.trim() : null,
      });

      try {
        const doc = await this.$root.$progressive(itemsRef.doc(item.id).get());
        let beforeData = doc.exists ? doc.data() : null;
        await this.$root.$progressive(itemsRef.doc(item.id).update(updateData));
        this.writeLog(['update'], `更新しました。`, item, { before: beforeData, after: updateData });  // 操作記録

        // 医療機関のみの処理
        if (this.type == 'customer') {
          //
          // オプション解析の契約が変更されていた場合は操作記録する
          //
          let msg = '';
          if (
            (
              !('is_contracted_analysis_option' in beforeData)
              || (
                'is_contracted_analysis_option' in beforeData
                && beforeData.is_contracted_analysis_option === false
              )
            )
            && 'is_contracted_analysis_option' in item
            && item.is_contracted_analysis_option === true
          ) {
            // 契約済みに変更された場合
            msg = '未契約 → 契約済み';
          } else if (
            'is_contracted_analysis_option' in beforeData
            && beforeData.is_contracted_analysis_option === true
            && (
              !('is_contracted_analysis_option' in item)
              || (
                'is_contracted_analysis_option' in item
                && item.is_contracted_analysis_option === false
              )
            )
          ) {
            // 未契約に変更された場合
            msg = '契約済み → 未契約';
          }
          if (msg != '') {
            this.$functions.log({
              tags: ['analysis_opinion_type', 'contract_change'],
              message: `${item.name}：${msg}：オプション解析の契約を変更しました。`
            });
          }

          //
          // 解析オプションが変更されていた場合も操作記録する
          //
          if (beforeData.analysis_opinion_type != item.analysis_opinion_type) {
            this.$functions.log({
              tags: ['analysis_opinion_type', 'contract_change'],
              message: `${item.name}：${this.analysisOpinionTypeLabels[beforeData.analysis_opinion_type]} → ${this.analysisOpinionTypeLabels[item.analysis_opinion_type]}：デフォルト解析オプションを変更しました。`
            });
          }
        }

        this.dialogModel = false;
        this.$emit('input', false);
        this.$emit('closed', item);
      } catch (e) {
        console.error(e);
        await this.$root.$alert('エラー', `${this.typeName}の保存に失敗しました。`);
      }
    },
    async deleteItem(item) {
      // 削除確認のダイアログを表示
      const result = await
        this.$root.$confirm(`確認`, `${this.typeName} ${item.name}を本当に削除しますか？`);

      if (result) {
        // 削除処理
        try {
          const promise = this.$db.runTransaction(async (t) => {
            // 組織の論理削除処理
            const orgRef = itemsRef.doc(item.id);
            t = await t.update(orgRef, {
              deleted: this.$firebase.firestore.FieldValue.serverTimestamp()
            });
            let staffsRef = this.$db.collection('staffs');
            if (this.itemsName === 'customer_orgs') {
              staffsRef = this.$db.collection('customer_staffs');
            }
            // 所属スタッフの削除処理関連
            const staffsSnapshot = await staffsRef
              .where('org_id', '==', item.id)
              .get();
            staffsSnapshot.forEach(async (doc) => {
              // 所属スタッフの論理削除
              const staffData = doc.data();
              const updateStaffRef = staffsRef.doc(doc.id);
              t = await t.update(updateStaffRef, {
                deleted: this.$firebase.firestore.FieldValue.serverTimestamp()
              });
              // ロールから削除
              const roleDocRef = this.$db.doc(`roles/${staffData.type}/staffs/${staffData.email}`);
              t = await t.delete(roleDocRef);
            });
          });
          await this.$root.$progressive(promise);

          this.writeLog(['delete'], `削除しました。`, item);  // 操作記録
        } catch (e) {
          console.error(e);
          await this.$root.$alert('エラー', `${this.typeName} ${item.name} の削除に失敗しました。`);
        }
      }
      this.$emit('input', false);
      this.$emit('closed', item);
    },
    async revertItem(item) {
      // 復旧確認のダイアログを表示
      const result = await this.$root.$confirm(`確認`, `${this.typeName} ${item.name}を元に戻しますか？`);
      if (result) {
        // 復元処理
        try {
          await this.$root.$progressive(itemsRef.doc(item.id)
            .update({deleted: null}));
          this.writeLog(['revert'], `復元しました。`, item);  // 操作記録
        } catch (e) {
          console.error(e);
          await this.$root.$alert('エラー', `ユーザーアカウント ${item.email} の復元に失敗しました。`);
        }
      }
      this.$emit('input', false);
      this.$emit('closed', item);
    },
    // 各種アクションボタンクリック
    async onClickActionBtn(functionName, item) {
      // 処理中の場合は終了
      if (this.isActionBtnDisabled) return;
      this.isActionBtnDisabled = true;
      try {
        // 指定された関数の実行
        await this[functionName](item);
      } finally {
        // 処理中の解除
        this.isActionBtnDisabled = false;
      }
    },
    async editAnalysisOpinionType(item) {
      if ('is_contracted_analysis_option' in item && item.is_contracted_analysis_option === true) {
        // オプション解析が契約済みの場合
        if (
          (
            !this.item
            || this.item.analysis_opinion_type !== item.analysis_opinion_type
          )
          && (
            item.analysis_opinion_type === this.ANALYSIS_OPINION_TYPES.TECHNICIAN
            || item.analysis_opinion_type === this.ANALYSIS_OPINION_TYPES.DOCTOR
          )
        ) {
          // 解析オプションの値が変わっている、かつ変更後の解析オプションが技師解析、医師所見の場合
          const result = await this.$root.$confirm(`確認`, `「解析/所見」で「技師解析」又は「医師所見」が選択されているため、解析時に追加料金が発生します。よろしいですか？`);
          if (!result) return null;
        }
      } else {
        // オプション解析が未契約の場合、解析オプションは強制的に自動解析にする
        item.analysis_opinion_type = this.ANALYSIS_OPINION_TYPES.AUTO;
        item.is_contracted_analysis_option = false; // 設定されていない可能性もあるため、明示的にに未契約にする
      }
      return item;
    },
    writeLog(tags, message, item, option = null) {
      tags = [this.itemsName].concat(tags);
      message = `${this.typeName}：${item.name}：${message}`
      this.$functions.log({ tags, message, item: option });
    },
  },
  mounted() {
    itemsName = this.itemsName;
    // console.log('UserDialog mounted', this.mode);
    itemsRef = this.$db.collection(`${itemsName}`);

    // itemがnullとなるケースがあるため、item自体の存在確認も行う
    if (this.item && this.item.deleted) {
      this.isDeletedItem = true;
    }

    this.currentItem = JSON.parse(JSON.stringify(this.item));
    this.currentMode = this.mode;
    this.dialogModel = this.value;

    if (this.mode == 'delete') {
      this.deleteItem(this.currentItem);
      return;
    } else if (this.mode == 'revert') {
      this.revertItem(this.currentItem);
      return;
    } else if (this.mode == 'new') {
      this.newItemForm();
    } else if (this.mode == 'view') {
      this.viewItemForm();
    } else if (this.mode == 'edit') {
      this.editItemForm();
    }
  },
  watch: {
    value(value) {
      this.dialogModel = value;
    },
    item(newItem) {
      console.log(newItem);
    },
    mode(newMode) {
      console.log('newMode', newMode);
    }
  }
}