import dateformat from 'dateformat';

let itemsRef = null;
let unsubscribeItems = null;

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      userDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '150px',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'email',
          width: '160px',
        },
        {
          text: '氏名',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '140px',
        },
        {
          text: 'ふりがな',
          align: 'left',
          sortable: true,
          value: 'name_kana',
          width: '140px',
        },
        {
          text: '電話番号',
          align: 'left',
          sortable: true,
          value: 'phone_number',
          width: '120px',
        },
        {
          text: '権限の種別',
          align: 'left',
          sortable: true,
          value: 'role',
          width: '120px',
        },
        {
          text: '通知メール',
          align: 'left',
          sortable: true,
          value: 'is_email_off',
          width: '120px',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'created',
          width: '100px',
        },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      filteredItems: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchSelectedField: 'Emailアドレス',
      searchFields: ['Emailアドレス', '氏名', '氏名（ふりがな）', '電話番号', '権限の種別', '備考'],
      filterSelectedField: '削除済み非表示',
      filterFields: ['全て表示', '削除済みのみ表示', '削除済み非表示'],
      searchKeyword: '',
      accessTypeModel: 'all',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      sortBy: ['name'],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    },
    isAdminRole() {
      if (this.$store.state.user.role.endsWith('-admin')
        || this.$store.state.user.role == 'management'
      ) {
        return true;
      }
      return false;
    },
    isManagementRole() {
      return this.$store.state.user.role.startsWith('management')
    },
    isCenterRole() {
      return this.$store.state.user.role.startsWith('center');
    }
  },
  methods: {
    getRoleName(role) {
      if (role.indexOf('-admin') !== -1) return '管理ユーザー';
      return '一般ユーザー';
    },
    closeDialog() {
    },
    async newItemForm() {
      // 組織の削除状態を確認
      const itemType = this.item.type == 'customer' ? 'customer_orgs' : 'staff_orgs';
      const orgDoc = await this.$db.collection(itemType).doc(this.item.id).get();
      if (!orgDoc.exists) {
        await this.$root.$alert('エラー', `所属組織の情報が取得できません。`);
        return;
      }
      const orgData = orgDoc.data();
      if (orgData.deleted) {
        await this.$root.$alert('エラー', `所属する組織が削除されているためスタッフを追加できません。`);
        return;
      }
      // スタッフ追加処理の続行
      this.mode = 'new';
      this.userDialogModel = true;
    },
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.mode = 'view';
      this.currentItem = item;
      this.userDialogModel = true;
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.userDialogModel = true;
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    revertItem(item) {
      this.mode = 'revert';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    subscribeItems() {

      // コレクション指定
      // ※ 運用主体・販売組織は、医療機関スタッフ・スタッフコレクション両方可能
      if (this.$store.state.user.role.startsWith('customer')) {
        // 医療機関ユーザーの場合は医療機関スタッフコレクションのみ
        this.itemsName = 'customer_staffs';
      } else if (!this.$store.state.user.role.startsWith('management') && !this.$store.state.user.role.startsWith('center')) {
        // 運用主体／販売組織以外は、スタッフコレクションのみ
        this.itemsName = 'staffs';
      }

      console.log('subscribeItems = ', this.item.name, this.itemsName, this.item);
      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let query = itemsRef
        .where('org_id', '==', this.item.id); // 運営主体

      if (!this.isAdminRole) {
        query = this.getQueryForFindSelf(query);
      } else {
        if (this.searchSelectedField == '権限の種別') {
          query = this.getQueryForSerchRole(query);
        } else if (this.searchKeyword.length > 0) {
          // 検索キーワードで前方検索
          query = this.getQueryForSerchKeyword(query);
        } else {
          query = query.orderBy('modified');
        }
        query = query.limit(this.$store.state.configs.query_limit);
      }

      unsubscribeItems = query
        .onSnapshot((querySnapshot) => {
            // 最初および変更時の読み取り
            let items = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              console.log('querySnapshot forEach', doc);
              data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              
              for (const key in data.terms_of_services) {
                const service = data.terms_of_services[key];
                const pdf = this.getTermsOfServicesPdfInfo(key);
                if (!pdf) {
                  delete data.terms_of_services[key];
                  continue;
                }
                service['label'] = pdf.label;
                service['datetime'] = this.timestampToString(service['datetime']);
              }

              data.id = doc.id;
              if (!('is_email_off' in data)) data['is_email_off'] = false;
              items.push(data);
            });
            this.items = items.reverse();
            this.filterItems();
            //console.log('onSnapshot', this.items);
        }, (e) => {
          console.log('error', e);
          this.items = [];
        });
    },
    filterItems() {
      if (this.filterSelectedField === '全て表示') {
        this.filteredItems = this.items;
        return;
      }
      if (this.filterSelectedField === '削除済み非表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (!item.deleted) {
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
      if (this.filterSelectedField === '削除済みのみ表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (item.deleted) {
            console.log('fileter item > item.deleted:', item.deleted);
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
    },
    getQueryForSerchKeyword(query) {
      console.log('getQueryForSerchKeyword:input', query);
      let builtQuery = query
      let startAt = this.searchKeyword;
      let endAt = this.searchKeyword + '\uffff';
      let searchFieldsValues = {
        'Emailアドレス':'email',
        '氏名': 'name',
        '氏名（ふりがな）': 'name_kana',
        '電話番号': 'phone_number',
        '備考': 'remarks'
      };
      // 指定したキーで前方検索
      builtQuery = builtQuery
        .orderBy(searchFieldsValues[this.searchSelectedField])
        .startAt(startAt).endAt(endAt);
        console.log('getQueryForSerchKeyword:output', builtQuery);
      return builtQuery;
    },
    getQueryForSerchRole(query) {
      let builtQuery = query;
      if (this.accessTypeModel != 'all' ) {
        let role = this.accessTypeModel == 'admin' ? this.item.type + '-admin': this.item.type;
        builtQuery = builtQuery
          .where('role', '==', role)
          .orderBy('modified');
      } else {
        this.accessTypeModel = 'all';
      }
      return builtQuery;
    },
    getQueryForFindSelf(query) {
      let builtQuery = query
      builtQuery = builtQuery
        .where('email', '==', this.$store.state.user.email);
      return builtQuery;
    },
    updateLogDate() {
      // console.log('updateLogDate');
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    getTermsOfServicesPdfInfo(serviceType) {
      for (const pdf of this.$store.state.termsOfServicePdfs) {
        const pdfServiceType = pdf.name.split('-')[1];
        if (pdfServiceType == serviceType) return pdf;
      }
      return null;
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;
      } catch (e) {
        return null;
      }
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      console.log('this.$store.state.user.id', this.$store.state.user.id);
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        let staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        if (this.$store.state.user.role.startsWith('customer')) {
          staffsRef = this.$db
          .collection('customer_staffs')
          .doc(this.$store.state.user.id);
        }
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    }
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();
    this.$functions.log({ tags: [this.itemsName, 'list'], message: `${this.typeName}：${this.item.name}：所属スタッフ一覧を表示しました。` });
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  }
}