import dateformat from 'dateformat';
let unsubscribeItems = null;
let itemsRef = null;

// 組織データ編集のためのミックスイン
export default {
  data() {
    return {
      orgDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '150px',
        },
        {
          text: '組織名',
          align: 'left',
          sortable: true,
          value: 'name',
          width: '160px',
        },
        {
          text: '住所',
          align: 'left',
          sortable: true,
          value: 'address',
          width: '140px',
        },
        {
          text: '電話番号',
          align: 'left',
          sortable: true,
          value: 'phone_number',
          width: '120px',
        },
        {
          text: '登録日時',
          align: 'left',
          sortable: true,
          value: 'created',
          width: '100px',
        },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      filteredItems: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchKeyword: '',
      accessTypeModel: 'all',
      // 上書きされる可能性のあるプロパティ
      searchSelectedField: '組織名',
      searchFields: ['組織名', '住所', '電話番号'],
      filterSelectedField: '削除済み非表示',
      filterFields: ['全て表示', '削除済みのみ表示', '削除済み非表示'],
      roleNames: {
        'center': '一般ユーザー',
        'center-admin': '管理ユーザー'
      },
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50]
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    }
  },
  methods: {
    closeDialog() {
    },
    newItemForm() {
      this.mode = 'new';
      this.orgDialogModel = true;
    },
    viewItemForm(item) {
      // console.log('viewItemForm', item);
      this.mode = 'view';
      this.currentItem = item;
      this.orgDialogModel = true;
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.orgDialogModel = true;
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    revertItem(item) {
      this.mode = 'revert';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    subscribeItems() {
      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();

      // 運営主体スタッフ／販売組織スタッフでなければ終了
      if (!this.$store.state.user.role.startsWith('management') && !this.$store.state.user.role.startsWith('center')) return;

      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let cRef = itemsRef
        .where('type', '==', this.type)
        .orderBy('modified').limit(this.$store.state.configs.query_limit); // 全て対象の時

      if (this.searchKeyword.length > 0) {
        // 検索キーワードで前方検索
        let startAt = this.searchKeyword;
        let endAt = this.searchKeyword + '\uffff';
        let searchFieldsValues = {
          '組織名':'name',
          '住所': 'address',
          '電話番号': 'phone_number',
        };
        // 指定したキーで前方検索
        cRef = itemsRef
          .where('type', '==', this.type)
          .orderBy(searchFieldsValues[this.searchSelectedField])
          .startAt(startAt).endAt(endAt).limit(this.$store.state.configs.query_limit);
      }

      if (this.searchSelectedField == '権限の種別' && this.accessTypeModel != 'all' ) {
        let role = this.accessTypeModel == 'admin' ? this.roleNames[1]: this.roleNames[2];
        cRef = itemsRef
          .where('type' == this.type)
          .where('role', '==', role)
          .orderBy('modified').limit(this.$store.state.configs.query_limit); // 全て対象の時
      } else {
        this.accessTypeModel = 'all';
      }
      unsubscribeItems = cRef
        .onSnapshot((querySnapshot) => {
            // 最初および変更時の読み取り
            let items = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              data.created = data.created ? dateformat(new Date(data.created.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.modified = data.modified ? dateformat(new Date(data.modified.seconds * 1000), 'yyyy/mm/dd HH:MM:ss'): '';
              data.id = doc.id;

              for (const key in data.terms_of_services_for_analysis) {
                const service = data.terms_of_services_for_analysis[key];
                const pdf = this.getTermsOfServicesPdfInfo(key);
                if (!pdf) {
                  delete data.terms_of_services_for_analysis[key];
                  continue;
                }
                service['label'] = pdf.label;
                service['datetime'] = this.timestampToString(service['datetime']);
              }

              items.push(data);
            });
            this.items = items.reverse();

            this.filterItems();
            //console.log('onSnapshot', this.items);
        }, (e) => {
          console.log('error', e);
          this.items = [];
        });
    },
    filterItems() {
      if (this.filterSelectedField === '全て表示') {
        this.filteredItems = this.items;
        return;
      }
      if (this.filterSelectedField === '削除済み非表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (!item.deleted) {
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
      if (this.filterSelectedField === '削除済みのみ表示') {
        const filteringItem = [];
        for (const item of this.items) {
          if (item.deleted) {
            filteringItem.push(item);
          }
        }
        this.filteredItems = filteringItem;
        return;
      }
    },
    updateLogDate() {
      // console.log('updateLogDate');
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    getTermsOfServicesPdfInfo(serviceType) {
      for (const pdf of this.$store.state.termsOfServiceForAnalysisPdfs) {
        const pdfServiceType = pdf.name.split('-')[1];
        if (pdfServiceType == serviceType) return pdf;
      }
      return null;
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;
      } catch (e) {
        return null;
      }
    },
  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    }
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();
    this.$functions.log({ tags: [this.itemsName, 'list'], message: `${this.typeName}：組織の一覧を表示しました。` });
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  }
}